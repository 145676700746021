import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService,Store } from '../shared/auth.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class PaymentGateWayService {
  
  private store:Store;
  private _paymentGatewayChanges = new BehaviorSubject<any>(null)
  _paymentGatewayObservable = this._paymentGatewayChanges.asObservable()

  constructor(private helper: Helper, private _auth:AuthService) {
    this.store = this._auth.getStore();
  }

  gatePaymentGatewayData(){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_PAYMENT_GATEWAYS, {})
  }

  getPaymentGatewayList(){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.PAYMENT_GATEWAY_LIST, {})
  }

  updateCashPaymentGateway(json){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_CASH_PAYMENT_GATEWAYS, json)
  }

  updatePaymentGateWay(json){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_PAYMENT_GATEWAYS, json)
  }

}
